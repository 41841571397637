import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import loadable from '@loadable/component';
import { withTheme } from 'styled-components';

import { PRODUCT_VIEW } from 'common/constants/GTMConstants';
import { fireGTMEvent } from 'common/utils/gtm';

const NewProductsVariant = loadable(props =>
  import(`common/blocks/products/new/${props.blockVariant}`)
);

import {
  reactiveTemplateConfigSelector
} from 'common/selectors/theme';

import { getNewProductsSelector } from 'common/selectors/page';

const NewProducts = ({
  t,
  settings = {},
  blockSettings,
  products,
  headline = null,
  theme,
  gtm = { list: 'New' }
}) => {
  if (_.isEmpty(products)) {
    return null;
  }
  React.useEffect(() => {
    fireGTMEvent(PRODUCT_VIEW, products, _.get(gtm, 'list', 'New'));
  },[])
  return (
    <Fragment>
      {headline}
      <NewProductsVariant
        t={t}
        blockVariant={_.get(blockSettings, 'blockVariant')}
        settings={settings}
        data={products}
        theme={theme}
        gtm={gtm}
      />
    </Fragment>
  );
};

const makeMapStateToProps = () => {
  const reactiveTemplateConfig = reactiveTemplateConfigSelector('NewProductsVariant');
  const getProducts = getNewProductsSelector();
  return (state, props) => {
    return {
      products: getProducts(state, props),
      blockSettings: {
        blockVariant: reactiveTemplateConfig(state, props)
      }
    };
  };
};

const NewProductsConnected = connect(makeMapStateToProps)(NewProducts);

export default withTranslation()(
  withTheme(NewProductsConnected)
);
